<template>
  <div id="billing">
    <section class="billing-option mb-3">
      <div class="box-header mb-2">Payment Method</div>
      <div class="underlined-table-rows mb-4">
        <div class="table-row">
          <div class="col select f-1"><input type="radio" name="payment-method" value="Credit Card" v-model="paymentMethod" /></div>
          <div class="col font-weight-bold f-5">Credit Card</div>
        </div>
        <div class="table-row" v-if="$store.state.userData.financing == true">
          <div class="col select f-1"><input type="radio" name="payment-method" value="Invoice" v-model="paymentMethod" /></div>
          <div class="col font-weight-bold f-5">Invoice (Financing)</div>
        </div>
      </div>
    </section>
    <section class="billing-address mb-3" v-if="paymentMethod == 'Invoice' && !financingAllowed">
      <div class="box-header mb-2">Financing Information</div>
      <div class="content">
        <div class="red-links">Your account is not setup for financing. To setup financing, contact us at <a href="tel:5034859019">503.485.9019</a> or <a href="mailto:fermentation@oregonfruit.com">fermentation@oregonfruit.com</a>. Financing approval will take up to four weeks.</div>
      </div>
    </section>
    <section class="billing-address mb-3">
      <div class="box-header mb-2">Billing Address</div>
      <div class="content mb-3" v-if="$store.state.billingAddresses.length > 0">
        <div class="underlined-table-rows">
          <AddressLine class="my-2" v-for="address in $store.state.billingAddresses" v-bind:key="address.id" :address="address" v-on:address-selected="addressSelected"></AddressLine>
          <div class="address-line">
            <div class="select">
              <input type="radio" name="address" class="mr-2" v-model="$store.state.orderInfo.billingAddress" :value="null" />
            </div>
            <div class="label">
              <h5 class="mb-0">Add New Address</h5>
            </div>
          </div>
        </div>
      </div>
      <div class="content mb-3" v-if="$store.state.orderInfo.billingAddress == null">
        <h4>New Address</h4>
        <form ref="billing-form" class="form" @submit.prevent="saveNewAddress">
          <div class="form-item">
            <input type="checkbox" v-model="useShippingAddressAsBilling" /> <label>Use Shipping Address As Billing</label>
          </div>
          <div class="form-item">
            <label>Country</label>
            <select v-model="newBillingAddress['country']" required>
              <option value="US">United States</option>
              <option value="CA">Canada</option>
            </select>
          </div>
          <div class="form-item half">
            <label>Name</label>
            <input type="text" v-model="newBillingAddress['name']" required />
          </div>
          <div class="form-item half">
            <label>Company</label>
            <input type="text" v-model="newBillingAddress['company']" required />
          </div>
          <div class="form-item half">
            <label>Address</label>
            <input type="text" v-model="newBillingAddress['address1']" required />
          </div>
          <div class="form-item half">
            <label>Address Line 2</label>
            <input type="text" v-model="newBillingAddress['address2']" />
          </div>
          <div class="form-item half">
            <label>City</label>
            <input type="text" v-model="newBillingAddress['city']" required />
          </div>
          <div class="form-item half" v-if="newBillingAddress['country'] != 'CA'">
            <label>State</label>
            <select v-model="newBillingAddress['state']" required>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="GU">Guam</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="PR">Puerto Rico</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VI">Virgin Islands</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
          <div class="form-item half" v-if="newBillingAddress['country'] == 'CA'">
              <label>Province</label>
              <select v-model="newBillingAddress['state']" required>
                <option value="">Choose Province</option>
                <option value="AB">Alberta</option>
                <option value="BC">British Columbia</option>
                <option value="MB">Manitoba</option>
                <option value="NB">New Brunswick</option>
                <option value="NL">Newfoundland and Labrador</option>
                <option value="NS">Nova Scotia</option>
                <option value="ON">Ontario</option>
                <option value="PE">Prince Edward Island</option>
                <option value="QC">Quebec</option>
                <option value="SK">Saskatchewan</option>
                <option value="NT">Northwest Territories</option>
                <option value="NU">Nunavut</option>
                <option value="YT">Yukon</option>
              </select>
            </div>
          <div class="form-item half">
            <label v-html="newBillingAddress.country == 'CA' ? '6 Digit Postal Code (xxxxxx)' : '5 Digit Zipcode (xxxxx)'"></label>
            <input type="text" v-model="newBillingAddress['zipcode']" :maxlength="newBillingAddress.country == 'CA' ? 6 : 5" required />
          </div>
          <div class="form-item half">
            <label>Phone</label>
            <input type="text" v-model="newBillingAddress['phone']" required />
          </div>
          <div class="form-item mt-3">
            <input type="submit" class="button red-bg" value="Save Address" />
          </div>
        </form>
      </div>
    </section>
    <section class="billing-address" v-if="paymentMethod != 'Invoice' && $store.state.orderInfo.billingAddress != null">
      <div class="box-header mb-2">Credit Card Info</div>
      <div class="content">
        <form ref="cc-form" class="form">
          <div class="form-item">
            <label>Credit Card #</label>
            <input type="text" class="cc-number" v-model="ccInfo.number" required />
            <div class="d-flex align-items-center mt-2">
              <span class="mr-1"><i class="fab fa-cc-visa"></i></span>
              <span class="mr-1"><i class="fab fa-cc-amex"></i></span>
              <span><i class="fab fa-cc-mastercard"></i></span>
            </div>
          </div>
          <div class="form-item half">
            <label>First Name</label>
            <input type="text" class="first-name" v-model="ccInfo.firstName" required />
          </div>
          <div class="form-item half">
            <label>Last Name</label>
            <input type="text" class="last-name" v-model="ccInfo.lastName" required />
          </div>
          <div class="form-item half">
            <label>Exp. Month</label>
            <select placeholder="Choose A Month" class="exp-month" v-model="ccInfo.month" required>
              <option value="">Choose A Month</option>
              <option value="01">January</option>
              <option value="02">February</option>
              <option value="03">March</option>
              <option value="04">April</option>
              <option value="05">May</option>
              <option value="06">June</option>
              <option value="07">July</option>
              <option value="08">August</option>
              <option value="09">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="form-item half">
            <label>Exp. Year</label>
            <input type="text" class="exp-year" v-model="ccInfo.year" maxlength="2" required />
          </div>
          <div class="form-item half">
            <label>CVV Number</label>
            <input type="text" class="cvv" v-model="ccInfo.cvv" required />
          </div>
        </form>
      </div>
    </section>
    <a class="checkout button mt-3" @click="$store.commit('changeOrderStep', 'review')" v-if="$store.state.orderStep == 'billing' && $store.getters.isOrderComplete && financingAllowed">Review Order</a>
  </div>
</template>

<script>
import AddressLine from '@/components/fermentation/AddressLine.vue'
import Api from '@/models/Api.js'
import GlobalMethods from '@/components/fermentation/GlobalMethods.js'

export default {
  name: 'Billing',
  components: {
    AddressLine
  },
  data() {
    return {
      newBillingAddress: {
        type: 'billing'
      },
      ccInfo: {},
      useShippingAddressAsBilling: false
    };
  },
  watch: {
    ccInfo: {
      handler(newVal) {
        if (newVal) {
          newVal.number = this.filterCcNumber(newVal.number);
          this.$store.commit('setCcInfo', newVal)
        }
      },
      deep: true
    },
    useShippingAddressAsBilling(newVal) {
      if (newVal) {
        let shippingAddress = this.$store.state.shippingAddresses.filter(obj => {
          return obj.id == this.$store.state.orderInfo.shippingAddress;
        });
        this.newBillingAddress = {
          name: shippingAddress[0].name,
          company: shippingAddress[0].company,
          address1: shippingAddress[0].address1,
          address2: shippingAddress[0].address2,
          city: shippingAddress[0].city,
          state: shippingAddress[0].state,
          zipcode: shippingAddress[0].zipcode,
          country: shippingAddress[0].country,
          phone: '',
          type: 'billing'
        };
      } else {
        this.clearExistingAddress();
      }
    }
  },
  computed: {
    paymentMethod: {
      get() {
        return this.$store.state.orderInfo.paymentMethod;
      },
      set(value) {
        this.$store.commit('setPaymentMethod', value);
      }
    },
    financingAllowed() {
      if (this.paymentMethod == 'Invoice' && this.$store.state.userData.financing != true) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    ...GlobalMethods,
    filterCcNumber(val) {
      return val.replace(/[^0-9]/gi, '');
    },
    clearExistingAddress() {
      this.newBillingAddress = {
        name: '',
        company: '',
        id: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        zipcode: '',
        country: 'US',
        type: 'billing'
      };
    },
    addressSelected(address) {
      this.$store.commit('setBillingAddress', address);
    },
    saveNewAddress() {
      let newAddress = { customer_id: this.$store.state.userData.id, ...this.newBillingAddress };
      Api.postAuth('account/address', newAddress, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$store.commit('getUserAddresses');
          this.$store.commit('setBillingAddress', response);
        }
      });
    },
    getFinancingStatus() {
      Api.getAuth('account/'+this.$store.state.userData.id, this.$store.state.userData.token).then(response => {
        if (response.financing != null) {
          let userData = this.$store.state.userData;
          userData['financing'] = response.financing;
          this.$store.commit('setUserData', userData);
        }
      });
    },
    checkFreeOrder() {
      if (this.$store.getters.cartTotal == '0.00') {
        this.$store.commit('setPaymentMethod', 'Free');
        this.$store.commit('changeOrderStep', 'review');
      }
    }
  },
  beforeMount() {
    this.checkFreeOrder();
    this.checkOrderProgress();
    this.getFinancingStatus();
  },
  mounted() {
    this.billingAddress = this.$store.state.orderInfo.billingAddress;
    this.newBillingAddress.name = this.$store.state.userData.firstName+' '+this.$store.state.userData.lastName;
    this.newBillingAddress.company = this.$store.state.userData.displayName;
  }
}
</script>

<style lang="scss" scoped>

</style>