<template>
  <div id="view-account" class="red-links">
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-lg-3">
          <div class="sohne-breit text-uppercase mb-3">My Account</div>
          <nav class="d-flex flex-column account-sidebar">
            <a class="mb-2 black" :class="(show == 'profile') ? 'font-weight-bold' : ''" @click="setShow('profile')">Profile</a>
            <a class="mb-2 black" :class="(show == 'shippingAddresses') ? 'font-weight-bold' : ''" @click="setShow('shippingAddresses')">Manage Shipping Addresses</a>
            <a class="mb-2 black" :class="(show == 'billingAddresses') ? 'font-weight-bold' : ''" @click="setShow('billingAddresses')">Manage Billing Addresses</a>
            <a class="mb-2 black" :class="(show == 'product-sheets') ? 'font-weight-bold' : ''" @click="setShow('product-sheets')">Download Product Sheets</a>
            <a class="mb-2 black" :class="(show == 'order-history') ? 'font-weight-bold' : ''" @click="setShow('order-history')">Order History</a>
            <a class="mb-2 black" @click="$store.commit('changeOrderStep', 'products')">Back To Ordering</a>
          </nav>
        </div>
        <div class="col-12 col-lg-9">
          <section class="account-panel" v-if="show == 'profile'">
            <div class="box-header">Profile</div>
            <div class="content p-3">
              <form class="form" v-on:submit.prevent="updateAccount">
                <div class="form-item">
                  <input type="text" v-model="userData.displayName" placeholder="Company" />
                </div>
                <div class="form-item half">
                  <input type="text" v-model="userData.firstName" placeholder="First Name" />
                </div>
                <div class="form-item half">
                  <input type="text" v-model="userData.lastName" placeholder="Last Name" />
                </div>
                <div class="form-item">
                  <input type="text" v-model="userData.navId" placeholder="Account Number" disabled />
                  <div class="description">
                    This number connects your account to Oregon Fruit's internal systems. If this field is empty, please contact us to get your account linked.
                  </div>
                </div>
                <div class="form-item border py-2 px-3 mt-2">
                  <div class="checkbox">
                    <input type="checkbox" :checked="userData.financing" placeholder="Financing" disabled />
                    <label>Is financing enabled?</label>
                  </div>
                  <div class="description" v-if="userData.financing == false">
                    We must enable your account for financing. If you are interested in enabling this feature, please email us at <a href="mailto:fermentation@oregonfruit.com">fermentation@oregonfruit.com</a>.
                  </div>
                </div>
                <div class="form-item">
                  <button type="submit" class="button">Save Changes</button>
                </div>
              </form>
            </div>
          </section>

          <section class="account-panel" v-if="show == 'shippingAddresses'">
            <div class="box-header">Shipping Addresses</div>
            <div class="content addresses" v-if="$store.state.shippingAddresses.length > 0">
              <Address v-for="address in $store.state.shippingAddresses" v-bind:key="address.id" :address="address" v-on:address-updated="$store.commit('getUserAddresses')"></Address>
              <div class="w-100 text-center mt-2"><a class="button mb-3" @click="showNewAddress('shipping')">Add New Address</a></div>
            </div>
            <div class="content p-3 text-center" v-else>
              <h5 class="my-3">There are currently no saved addresses.</h5>
              <a class="button mb-3" @click="showNewAddress('shipping')">Add New Address</a>
            </div>
          </section>

          <section class="account-panel" v-if="show == 'billingAddresses'">
            <div class="box-header">Billing Addresses</div>
            <div class="content addresses" v-if="$store.state.billingAddresses.length > 0">
              <Address v-for="address in $store.state.billingAddresses" v-bind:key="address.id" :address="address" v-on:address-updated="$store.commit('getUserAddresses')"></Address>
              <div class="w-100 text-center mt-2"><a class="button mb-3" @click="showNewAddress('billing')">Add New Address</a></div>
            </div>
            <div class="content p-3 text-center" v-else>
              <h5 class="my-3">There are currently no saved addresses.</h5>
              <a class="button mb-3" @click="showNewAddress('billing')">Add New Address</a>
            </div>
          </section>

          <div class="popup new-address" v-if="show == 'newAddress'">
            <a class="close" @click="closeNewAddress"><i class="far fa-times"></i></a>
            <h4>Add New Address</h4>
            <form class="form" v-on:submit.prevent="saveNewAddress">
              <div class="form-item">
                <label>Is this a residential address?</label>
                <div class="d-flex align-items-center justify-content-start"><input type="radio" class="mr-1" v-model="isResidential" value="yes" required /> Yes <input type="radio" class="ml-2 mr-1" v-model="isResidential" value="no" required /> No</div>
              </div>
              <div class="form-item" v-if="isResidential == 'yes'">
                <p>We cannot ship to residential address. Please contact <a href="tel:5034859026">503.485.9026</a> if you have further questions.</p>
              </div>
              <div class="form" v-else>
                <div class="form-item">
                  <select v-model="newAddress.country" required>
                    <option value="">Choose Country</option>
                    <option value="US">United States</option>
                    <option value="CA">Canada</option>
                  </select>
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="Name" v-model="newAddress.name" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="Company" v-model="newAddress.company" />
                </div>
                <div class="form-item">
                  <input type="text" placeholder="Street Address" v-model="newAddress.address1" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="City" v-model="newAddress.city" />
                </div>
                <div class="form-item half" v-if="newAddress.country == 'US'">
                  <select v-model="newAddress.state" required>
                    <option value="">Choose State</option>
                    <option value="AL">Alabama</option>
                    <option value="AK">Alaska</option>
                    <option value="AZ">Arizona</option>
                    <option value="AR">Arkansas</option>
                    <option value="CA">California</option>
                    <option value="CO">Colorado</option>
                    <option value="CT">Connecticut</option>
                    <option value="DE">Delaware</option>
                    <option value="DC">District Of Columbia</option>
                    <option value="FL">Florida</option>
                    <option value="GA">Georgia</option>
                    <option value="GU">Guam</option>
                    <option value="HI">Hawaii</option>
                    <option value="ID">Idaho</option>
                    <option value="IL">Illinois</option>
                    <option value="IN">Indiana</option>
                    <option value="IA">Iowa</option>
                    <option value="KS">Kansas</option>
                    <option value="KY">Kentucky</option>
                    <option value="LA">Louisiana</option>
                    <option value="ME">Maine</option>
                    <option value="MD">Maryland</option>
                    <option value="MA">Massachusetts</option>
                    <option value="MI">Michigan</option>
                    <option value="MN">Minnesota</option>
                    <option value="MS">Mississippi</option>
                    <option value="MO">Missouri</option>
                    <option value="MT">Montana</option>
                    <option value="NE">Nebraska</option>
                    <option value="NV">Nevada</option>
                    <option value="NH">New Hampshire</option>
                    <option value="NJ">New Jersey</option>
                    <option value="NM">New Mexico</option>
                    <option value="NY">New York</option>
                    <option value="NC">North Carolina</option>
                    <option value="ND">North Dakota</option>
                    <option value="OH">Ohio</option>
                    <option value="OK">Oklahoma</option>
                    <option value="OR">Oregon</option>
                    <option value="PA">Pennsylvania</option>
                    <option value="PR">Puerto Rico</option>
                    <option value="RI">Rhode Island</option>
                    <option value="SC">South Carolina</option>
                    <option value="SD">South Dakota</option>
                    <option value="TN">Tennessee</option>
                    <option value="TX">Texas</option>
                    <option value="UT">Utah</option>
                    <option value="VT">Vermont</option>
                    <option value="VI">Virgin Islands</option>
                    <option value="VA">Virginia</option>
                    <option value="WA">Washington</option>
                    <option value="WV">West Virginia</option>
                    <option value="WI">Wisconsin</option>
                    <option value="WY">Wyoming</option>
                  </select>
                </div>
                <div class="form-item half" v-if="newAddress.country == 'CA'">
                  <select v-model="newAddress.state" required>
                    <option value="">Choose Province</option>
                    <option value="AB">Alberta</option>
                    <option value="BC">British Columbia</option>
                    <option value="MB">Manitoba</option>
                    <option value="NB">New Brunswick</option>
                    <option value="NL">Newfoundland and Labrador</option>
                    <option value="NS">Nova Scotia</option>
                    <option value="ON">Ontario</option>
                    <option value="PE">Prince Edward Island</option>
                    <option value="QC">Quebec</option>
                    <option value="SK">Saskatchewan</option>
                    <option value="NT">Northwest Territories</option>
                    <option value="NU">Nunavut</option>
                    <option value="YT">Yukon</option>
                  </select>
                </div>
                <div class="form-item half">
                  <input type="text" :placeholder="newAddress.country == 'CA' ? '6 Digit Postal Code (xxxxxx)' : '5 Digit Zipcode (xxxxx)'" v-model="newAddress.zipcode" required :maxlength="newAddress.country == 'CA' ? 6 : 5" />
                </div>
                <div class="form-item half">
                  <input type="text" placeholder="Phone" v-model="newAddress.phone" required />
                </div>
                <div class="form-item" v-if="newAddress.country == 'CA' && newAddress.type == 'shipping'">
                  <label>Tax ID</label>
                  <input type="text" placeholder="Tax ID" v-model="newAddress['tax_id']" />
                </div>
                <h4 class="form-item mt-2" v-if="newAddress.country == 'CA' && newAddress.type == 'shipping'">Customs Info</h4>
                <div class="form-item half" v-if="newAddress.country == 'CA' && newAddress.type == 'shipping'">
                  <input type="text" placeholder="Broker Name" v-model="newAddress['customs_broker_name']" />
                </div>
                <div class="form-item half" v-if="newAddress.country == 'CA' && newAddress.type == 'shipping'">
                  <input type="text" placeholder="Broker Phone" v-model="newAddress['customs_broker_phone']" />
                </div>
                <div class="form-item full">
                  <button type="submit" class="button">Save Address</button>
                </div>
              </div>
            </form>
          </div>

          <section class="account-panel" v-if="show == 'product-sheets'">
            <div class="box-header">Products Sheets</div>
            <div class="content p-3 d-flex flex-wrap red-links">
              <a class="w-50 d-inline-block py-1 text-uppercase text-1 font-weight-bold" v-for="file in fermentationFiles" v-bind:key="file.title" :href="file.url" target="_blank">{{ file.title }}</a>
              <a class="w-50 d-inline-block py-1 text-uppercase text-1 font-weight-bold" v-for="product in products" v-bind:key="product['ID']" :href="product.pdf.url" target="_blank">{{ product.post_title }}</a>
            </div>
          </section>

          <section class="account-panel" v-if="show == 'order-history'">
            <div class="box-header">Order History</div>
            <div class="content previous-orders" v-if="$store.state.previousOrders.length > 0">
              <div class="previous-order-item" v-for="previousOrder in $store.state.previousOrders" :key="previousOrder.id">
                <div class="top">
                  <div class="date">
                    <strong>ORDER PLACED</strong><br>
                    {{ formatDate(previousOrder.date_created) }}
                  </div>
                  <div class="total">
                    <strong>TOTAL</strong><br>
                    ${{ previousOrder.total_price }}
                  </div>
                  <div class="id">
                    <strong>Order #</strong><br>
                    {{ 'WEB'+(1000+parseInt(previousOrder.id)) }}
                  </div>
                  <div class="actions">
                    <a @click="setCurrentPreviousOrder(previousOrder)"><span>View Order</span> <i class="fas fa-chevron-down"></i></a>
                  </div>
                </div>
                <div class="bottom" v-if="currentPreviousOrder != null && currentPreviousOrder.id == previousOrder.id">
                  <div class="addresses">
                    <div class="shipping-address" v-if="currentPreviousOrderShippingAddress != undefined">
                      <strong>Shipping Address</strong><br>
                      {{ currentPreviousOrderShippingAddress.name }}<br>
                      {{ currentPreviousOrderShippingAddress.address1 }}<br>
                      {{ currentPreviousOrderShippingAddress.city }}, {{ currentPreviousOrderShippingAddress.state }} {{ currentPreviousOrderShippingAddress.zipcode }}
                    </div>
                    <div class="billing-address" v-if="currentPreviousOrderBillingAddress != undefined">
                      <strong>Billing Address</strong><br>
                      {{ currentPreviousOrderBillingAddress.name }}<br>
                      {{ currentPreviousOrderBillingAddress.address1 }}<br>
                      {{ currentPreviousOrderBillingAddress.city }}, {{ currentPreviousOrderBillingAddress.state }} {{ currentPreviousOrderBillingAddress.zipcode }}
                    </div>                    
                  </div>
                  <div class="order-items" v-if="currentPreviousOrderItems != null">
                    <strong>Order Items</strong><br>
                    <div class="order-item" v-for="orderItem in currentPreviousOrderItems" :key="orderItem.id">
                      {{ orderItem.name }} {{ orderItem.type }} (Qty: {{ orderItem.qty }}) ${{ orderItem.price }}/{{ orderItem.type }}
                    </div>
                    <a @click="addOrderItemsToCart(currentPreviousOrderItems)"><i class="far fa-cart-plus"></i> <span>Add Items To Cart</span></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="content white-bg box-shadow p-3 text-center" v-else>
              <h5 class="my-3">There are currently no previous orders. Find some fruit and change that!</h5>
            </div>
          </section>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/models/Api.js'
import Address from '@/components/fermentation/Address.vue'
import moment from 'moment'

export default {
  name: 'ViewAccount',
  components: {
    Address: Address
  },
  data() {
    return {
      show: 'profile',
      newAddress: {
        customer_id: this.$store.state.userData.id,
        type: '',
        country: 'US',
        name: '',
        company: '',
        address1: '',
        city: '',
        state: '',
        zipcode: '',
        phone: ''
      },
      userData: {
        displayName: '',
        firstName: '',
        lastName: '',
        navId: '',
        financing: false
      },
      products: null,
      fermentationFiles: null,
      isResidential: 'no',
      currentPreviousOrder: null,
      currentPreviousOrderItems: null
    };
  },
  computed: {
    currentPreviousOrderShippingAddress() {
      if (this.currentPreviousOrder == null) return null;
      let currentOrderShippingAddress = this.$store.state.shippingAddresses.find(shippingAddress => shippingAddress.id == this.currentPreviousOrder.shipping_address_id);
      return currentOrderShippingAddress;
    },
    currentPreviousOrderBillingAddress() {
      if (this.currentPreviousOrder == null) return null;
      let currentOrderBillingAddress = this.$store.state.billingAddresses.find(billingAddress => billingAddress.id == this.currentPreviousOrder.billing_address_id);
      return currentOrderBillingAddress;
    }
  },
  methods: {
    setShow(show) {
      this.show = show;
    },
    showNewAddress(type) {
      this.newAddress.type = type;
      this.setShow('newAddress');
    },
    saveNewAddress() {
      Api.postAuth('account/address', this.newAddress, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$store.commit('getUserAddresses');
          this.closeNewAddress();
          this.$overlay(true, "Your address was successfully added.", 2000);
        }
      });
    },
    closeNewAddress() {
      this.setShow(this.newAddress.type+'Addresses');
    },
    updateAccount() {
      Api.putAuth('account/'+this.$store.state.userData.id, this.userData, this.$store.state.userData.token).then(response => {
        if (response) {
          this.$overlay(true, "Your account was successfully updated.", 2000);
        }
      });
    },
    getUserData() {
      Api.getAuth('account/'+this.$store.state.userData.id, this.$store.state.userData.token).then(response => {
        this.userData = response;
      });
      Api.getAuth('account/'+this.$store.state.userData.id+'/orders', this.$store.state.userData.token).then(response => {
        this.$store.commit('setPreviousOrders', response);
      });
    },
    getProducts() {
      Api.get('purees').then(response => {
        this.products = response;
      });
    },
    getFermentationFiles() {
      Api.get('fermentation-files').then(response => {
        this.fermentationFiles = response;
      });
    },
    getFinancingStatus() {
      Api.getAuth('account/'+this.$store.state.userData.id, this.$store.state.userData.token).then(response => {
        if (response.financing != null) {
          this.userData.financing = response.financing;
        }
      });
    },
    formatDate(value) {
      let date = moment(value).format('LLL');
      return date;
    },
    setCurrentPreviousOrder(order) {
      this.currentPreviousOrderItems = null;
      if (this.currentPreviousOrder == order) {
        this.currentPreviousOrder = null;
        return;
      }
      this.currentPreviousOrder = order;
      this.getCurrentPreviousOrderItems();
    },
    getCurrentPreviousOrderItems() {
      Api.getAuth('order/'+this.currentPreviousOrder.id+'/items', this.$store.state.userData.token).then(response => {
        this.currentPreviousOrderItems = response;
      });
    },
    addOrderItemsToCart(orderItems) {
      orderItems.forEach(orderItem => {
        let product = this.products.find(product => product.ID == orderItem.item_id);
        if (product) {
          product.qty = orderItem.qty;
          product.type = orderItem.type;
          this.$store.commit('addToCart', { ...product });
          this.$overlay(true, 'Items were added to your cart.', 2000);
          this.$store.commit('changeOrderStep', 'cart');
        }
      });
    },
    setShowIfProvided() {
      if (this.$store.state.orderStepData != null) {
        this.setShow(this.$store.state.orderStepData.tab);
        this.$store.commit('setOrderStepData', null);
      }
    }
  },
  updated() {
    this.setShowIfProvided();
  },
  beforeMount() {
    this.getFinancingStatus();
  },
  mounted() {
    this.$store.commit('getUserAddresses');
    this.getUserData();
    this.getProducts();
    this.getFermentationFiles();
  }
}
</script>

<style lang="scss" scoped>

</style>